import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import Iframe from 'react-iframe'

import {
	Container,
	Section,
	Row,
	Column,

} from '../../components/layouts'

import {
	Title,
	Subtitle,
	Paragraph,
	Button,
	Image,
	Card,
	List,
	Collapsible,
} from '../../components/ui'

import theme from '../../theme/theme'

import {requestCall} from '../../factory'

const {
	introNonprofit,
	leaveImpact,
	intro,
	securityMatters,
	whatOffer,
	oneTimeDonation
} = theme.sections


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.scrolledElement = null

		this.state = {
			animateCards: false,
			contactFormSubmitted: false,
			showSignUpModal: false,
			open: true,
			totalSupporters: 100000,
			clicks: 5,
			donationRate: 1,
			accounts: 20,
			totalPledges: ''
		}

		this.smoothScroll = this.smoothScroll.bind(this)
		this.requestCall = this.requestCall.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
		this.smoothScroll()
	}

	componentWillUnmount() {
		typeof window !== 'undefined' && window.removeEventListener('scroll', this.handleScroll)
	}

	smoothScroll() {
		if (window.location.href.includes('#')) {
			const className = "#" + window.location.href.split('#')[1]
			try{
				const element = document.querySelector(className)
				element.scrollIntoView({
					behavior: 'smooth'
				})
			} catch{
				// do nothing
			}
		}
	}

	requestCall() {
		this.props.requestCall(this.state)
	}

	render() {
		return (
			<Layout>
				{/* Intro section */}
				<Section id="intro" bg={introNonprofit.bg} paddingTop={'72px'} smallPaddingTop={'8em'} paddingBottom={'0'}>
					<Container>
						{/* Intro first section */}
						<Row marginBottom={'2em'} smallMarginBottom={'1em'}>
							<Column vAlign={'center'} hAlign={'left'} maxWidth={'50%'}>
								<Title fontSize={'50px'} style={{margin: 0}}>Marketing Professionals to manage your <br/> Google Ad Grant</Title>
								<Row>
									<Column row>
										<Button target={"_blank"} href={'https://hello.flourishchange.com/meetings/bradenf/free-google-ad-grant-consult'} hoverBg={theme.colors.salomie} hoverTextColor={theme.colors.black} marginRight={'1.5em'} smallMarginRight={'1em'} smallMarginBottom={'1em'} maxWidth={'300px'}>Chat with us today!</Button>
									</Column>
								</Row>
							</Column>
						</Row>
					</Container>
				</Section>
				<Section style={{marginTop: -1}}>
					<Container>
						<Row marginBottom={'4em'}>
							<Column center>
								<Title fontSize={'33px'} textAlign={'center'}>Imagine what $10,000 in free advertising could do...</Title>
								<Paragraph maxWidth={'505px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>P.S. That is a ton of advertising dollars</Paragraph>
							</Column>
						</Row>
						<Row marginBottom={'4em'}>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={intro.content.secondPart.columns.third.image} marginBottom={'3em'} maxWidth={'234px'}  maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>Market your services, to everyone</Subtitle>
									<Paragraph height={'78px'} maxWidth={'505px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>Your goal is to maximize your impact. So let's make that happen by making <strong>EVERYONE</strong> aware of the awesome work you do.</Paragraph>
								</div>
							</Column>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={intro.content.secondPart.columns.first.image} marginBottom={'3em'} maxWidth={'234px'} maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>Publicize your next event, to thousands</Subtitle>
									<Paragraph height={'78px'} maxWidth={'505px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>With this much money, your next volunteer event, or fundraising effort, can be shown to thousands close to home, or across the globe.</Paragraph>
								</div>
							</Column>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={intro.content.secondPart.columns.second.image} marginBottom={'3em'} maxWidth={'234px'}  maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>Increase fundraising, locally and globally</Subtitle>
									<Paragraph height={'78px'} maxWidth={'500px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>Whether it's a mega-donor, or an average joe, making sure your brand appears in the right place, is key to driving donations.</Paragraph>
								</div>
							</Column>

						</Row>
					</Container>
				</Section>

				{/* Be online section */}
				<Section id="cardsContainer" bg={leaveImpact.bg} paddingTop={'3em'} paddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={'white'} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>
									What is Google's Ad Grant?
								</Title>
								<Paragraph color={'white'} maxWidth={'370px'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>Let's break down this awesome opportunity</Paragraph>
							</Column>
						</Row>
						<Row style={{marginLeft: 'auto', marginRight: 'auto'}}>
							<Column maxWidth={'50%'}>
								<Card animatedLeft className={this.state.animateCards ? 'animate' : false}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column style={{minHeight: '90%'}} vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>$10,000/mo in credits</Subtitle>
											<Paragraph fontSize={'14px'}>Google has a program to give elegible nonprofits $10,000 in Google Ad credits so you can spread your mission and reach new prospects.</Paragraph>
										</Column>
									</Row>
								</Card>
								<Card animatedLeft className={this.state.animateCards ? 'animate' : false}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column style={{minHeight: '90%'}} vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>Real Google Ads</Subtitle>
											<Paragraph fontSize={'14px'}>Google Ad Grants works just like Google Ads online advertising, by displaying your message to people who are searching for nonprofits like yours.</Paragraph>
										</Column>
									</Row>
								</Card>
							</Column>
							<Column maxWidth={'50%'}>
								<Card animatedRight className={this.state.animateCards ? 'animate' : false}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>Reach new prospects</Subtitle>
											<Paragraph fontSize={'14px'}>With Google Ad Grants, your organization appears next to similar nonprofits, and helps you reach people who were previously unaware of your cause.</Paragraph>
										</Column>
									</Row>
								</Card>
								<Card animatedRight className={this.state.animateCards ? 'animate' : false}>
									<Row marginBottom={'0'} xsmallNoColumn>
										<Column style={{minHeight: '90%'}} vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'75%'} xsmallMaxWidth={'75%'}>
											<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>Google wants you to Flourish</Subtitle>
											<Paragraph fontSize={'14px'}>The more frequently your ads appear next to Google search results, the more people will be aware of your cause, translating into more donations and volunteers.</Paragraph>
										</Column>
									</Row>
								</Card>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Security matters section */}
				<Section bg={securityMatters.bg} paddingTop={'5em'} paddingBottom={'5em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>Ready for your free consultation?</Title>
								<Paragraph color={theme.colors.white} maxWidth={'706px'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.5em'} fontWeight={'200'}>We are happy to chat with you about the program or help collect the materials to apply. At then end of the day, we want you to get the grant - whether you use our services or not!</Paragraph>
								<Button target={"_blank"} href={'https://hello.flourishchange.com/meetings/bradenf/free-google-ad-grant-consult'} hoverBg={theme.colors.salomie} hoverTextColor={theme.colors.black} marginLeft={'auto'} marginRight={'auto'} smallMarginLeft={'auto'} smallMarginRight={'auto'} smallMarginBottom={'1em'} maxWidth={'300px'}>Schedule a free consult today</Button>
							</Column>
						</Row>
					</Container>
				</Section>

				{/* Our offerings */}
				<Section paddingTop={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>Flourish Delivers Targeted Marketing </Title>
								<Paragraph maxWidth={'420px'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>We can help manage your $10,000.</Paragraph>
							</Column>
						</Row>
						<Row marginBottom={'1em'}>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/GoogleGrantPage/1.png'} width={"50%"} marginBottom={'3em'} maxWidth={'234px'} maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>Get the Grant</Subtitle>
									<Paragraph fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>Let us help you compile the information and get the grant! For free! You will never be charged until after you have received your $10,000/mo credit.</Paragraph>
								</div>
							</Column>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/GoogleGrantPage/2.png'} width={"50%"}  marginBottom={'3em'} maxWidth={'234px'} maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>Define your goals</Subtitle>
									<Paragraph fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>Understanding your goals are key to developing a good campaign. We spend time with every client to understand your needs, before starting ads.</Paragraph>
								</div>
							</Column>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/GoogleGrantPage/3.png'} width={"50%"} marginBottom={'3em'} maxWidth={'234px'} maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>Develop your messaging</Subtitle>
									<Paragraph fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>We work with your team to craft targeted keywords, and ideal placement, so the right people see your ads.</Paragraph>
								</div>
							</Column>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/GoogleGrantPage/4.png'} width={"50%"} marginBottom={'3em'} maxWidth={'234px'} maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>Manage your campaigns</Subtitle>
									<Paragraph fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>Our team tracks your success weekly, making sure the campaigns are performing to our standards.</Paragraph>
								</div>
							</Column>
							<Column maxWidth={'33.3333333333%'} vAlign={'between'}>
								<Image src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Website/GoogleGrantPage/5.png'} width={"50%"} marginBottom={'3em'} maxWidth={'234px'} maxHeight={"346px"} />
								<div>
									<Subtitle fontSize={'24px'} textAlign={'center'}>Navigate Google's requirements</Subtitle>
									<Paragraph fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.2em'} fontWeight={'300'}>Let our team worry about Google's 14 rules, making sure your account is always active, so you can focus on your impact.</Paragraph>
								</div>
							</Column>
						</Row>
					</Container>
				</Section>

				{/* Pricing*/}


				<Section bg={oneTimeDonation.bg} paddingTop={'8em'} paddingBottom={'3em'}>
					<Container>
						<Card style={{ maxWidth: '100%', height: 'inherit' }}>
							<Column>
								<Row style={{ marginTop: 15, marginBottom: 0 }}>
									<Column>
										<Title fontSize={'50px'} textAlign={'center'} marginBottom={'0.5em'}>Choose the Option That's Right for You</Title>
									</Column>
								</Row>
								<Column>
									<Collapsible list={whatOffer.content.mobileGrantList} />
									<Button
										href={'https://hello.flourishchange.com/meetings/bradenf/free-google-ad-grant-consult'}
										bgColor={'white'}
										hoverBg={theme.colors.crusta}
										hoverTextColor={'white'}
										fontColor={theme.colors.crusta}
										onClick={this.nextPosition}
										style={{margin: 'auto', width: '100%', marginTop: 10, paddingLeft: 0, paddingRight: 0, border: `1px solid ${theme.colors.crusta}`}}>
													Schedule a free consult!
									</Button>
									<Row style={{ marginTop: 15 }}>
										<div style={{marginTop: 10}}>
											<Paragraph style={{fontSize: 10, width: '100%', textAlign: 'right'}}>* Subject to implementation of proprietary analytics suite</Paragraph>
										</div>
									</Row>
								</Column>
							</Column>
						</Card>
					</Container>
				</Section>

				{/* schedule consult */}
				<Section>
					<Container>
						<Title fontSize={'33px'} textAlign={'center'} marginTop={'1em'} maxWidth={'380px'}>Want to learn more?</Title>
						<Paragraph maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={0} fontWeight={'200'}>We are always availible to discuss how you could benefit from this awesome program</Paragraph>
						<Row>
							<Column maxWidth={'60%'} vAlign={'between'} style={{marginTop: 0, paddingTop: 0}}>
								<Iframe url="https://hello.flourishchange.com/meetings/bradenf/free-google-ad-grant-consult?embed=true"
									width="auto"
									height="650px"
									id="myId"
									className="meetings-iframe-container"
									display="initial"
									position="relative"/>
							</Column>
							<Column maxWidth={'40%'} vAlign={'center'}>
								<Title fontSize={'33px'} textAlign={'center'} marginTop={'1em'}>Your consultation includes:</Title>
								<div style={{marginLeft: 25}}>
									<List list={
										[
											"Discuss advantages of Google Ad Grant",
											"Define Ad goals and new initiatives",
											"Compile information for Google's application",
											"Submit nonprofit verification with Techsoup"
										]
									} />
								</div>
							</Column>
						</Row>
					</Container>
				</Section>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	error: PropTypes.any,
	success: PropTypes.bool,
	pending: PropTypes.bool,
	requestCall: PropTypes.func
}

const mapStateToProps = (state) => {
	const { utilState } = state
	return {
		error: utilState.error,
		pending: utilState.pending,
		success: utilState.success,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		requestCall: (data) => dispatch(requestCall(data)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndexPage)
